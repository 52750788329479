<template>
  <div>
    <search-header :currentActive="8"></search-header>
    <div class="container">
      <div class="row news-item">
        <nav class="col-12 p-0 mt-3">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/news">资讯首页</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">{{getNewsClassValue}}</li>
          </ol>
        </nav>
        <ul class="col-8">
          <li class="row justify-content-between mb-3" :class="{ 'border-bottom pb-3' : (i+1) %5 == 0 }" v-for="m,i in list.records" :key="i">
            <div class="width-600 ellipsis"><router-link :to="'/news/detail?id=' + m.id" target="_blank">{{m.title}}</router-link></div>
            <div class="text-secondary">{{m.startTime ? m.startTime.split('T')[0] : ''}}</div>
          </li>
          <div v-if="list && list.total > list.size" class="my-4 d-flex justify-content-center">
             <b-pagination @change="onPageChange" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
          </div>
        </ul>
        <div class="col-4">
          <tool-right></tool-right>
          <div class="border my-3 px-3 py-3 width-364 rounded">
              <div class="h5 mb-3">推荐土地</div>
              <div class="row">
                <div class="col-6 mb-3" v-for="m in landList" :key="m.id">
                  <land-right :datas="m"></land-right>
                </div>
              </div>
           </div>
        </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
  import toolRight from "@/components/right/tool-right.vue";
  import landRight from "@/components/right/land-right.vue";
export default {
  components: {
    toolRight, landRight
  },
  data() {
    return {
      list:[],
      landList:[],
      para: {
        param: {
          contentType: ''
        },
        size: 20,
        current: 1
		  },
    };
  },
  computed: {
    ...mapGetters(['dict']),
    getNewsClassValue () {
      if(this.dict) {
        if(this.dict.newsClass && this.para.param.contentType) {
          let n = this.dict.newsClass.find(x=> x.id == this.para.param.contentType);
          if(n) {
            return n.text;
          }
        }
      }
      return '';
    },
  },
  watch:{
      '$route': {
         handler() {
            this.para.current = 1;
            this.getList();
         },
         deep:true //true 深度监听
      }
  },
  methods: {
    onPageChange(i) {
      this.para.current = i;
      this.getList();
    },
    getList() {
      this.para.param.contentType = this.$route.query.id;
      if(this.para.param.contentType > 0) {
        this.$http.post(this.$api.news.getList, this.para).then(res => {
          this.list = res.result;
          console.log(this.list)
        })
      }
		},
    getLandList() {
      this.$http.post(this.$api.supply.getNeedsForLetList, {
          param: {}, 
          size: 4,
          current: 1 
      }).then((res) => {
          this.landList = res.result.records.map(x=> {
              let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
              return {
                  id: x.id,
                  fileUrl: f ? f.fileUrl : '',
                  title: x.title,
                  price: x.price,
                  priceUnit: x.priceUnit,
                  measureArea: x.measureArea,
                  acreageUnit: x.acreageUnit,
              }
          });
      });
    },
  },
  created() {
    this.getList();
    this.getLandList();
  }
}
</script>

<style scoped>
</style>
